<template>
  <div @click="gotoDetail()">
    <span class="ml-2 text-primary">
      <u class="cursor-pointer  font-bold" style="color: #2367B2;">เผยแพร่</u>
      <!-- <button @click="sayHi">Edit Item</button> -->
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  created() {},
  methods: {
    gotoDetail() {
      if (this.nowSlug == "second") {
        this.$store.dispatch("general/getRenderDetail", {
          path: this.nowSlug,
          detail: "comp_card",
          id_user: this.params.value
        });
      } else {
        this.$store.dispatch("general/getRenderDetail", {
          path: this.nowSlug,
          detail: null,
          id_user: this.params.value
        });
      }
      this.$store.commit("general/GET_ID_USER", this.params.value);
      this.$router.push("/detail");
    }
  },
  computed: {
    ...mapGetters({
      nowPage: "getNowPage",
      nowSlug: "getNowSlug",
      tabActive: "general/tabActive"
    })
  },
  updated() {
    // console.log("nowPage", this.nowPage);
  }
};
</script>
