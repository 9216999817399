<template>
    <div class="modal-backdropdrop">
        <div class="modalmodal w-4/5 lg:w-3/5" role="dialog" aria-labelledby="modalTitle"
            aria-describedby="modalDescription">
            <header class="modal-header" id="modalTitle">
                <button type="button" class="close-btn" @click="$emit('close')" aria-label="Close Modal">
                    x
                </button> 
            </header>

            <main class="modal-body overflow-auto" id="modalDescription">
                <slot name="body"> Default body content </slot>
                 <!-- <button type="button" class="close-btn" @click="$emit('close')" aria-label="Close Modal">
                    x
                </button> -->
            </main>

            <!-- <footer class="modal-footer">
                <slot name="footer"> Default Footer! </slot>
            </footer> -->
        </div>
    </div>
</template>

<script>

export default {
    data() {
    return {
    }
    },
    methods: {
        close() {
            //emit here
        }
    },
}
</script>

<style lang="scss" scoped>
.modal-backdropdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 9999;
    align-items: center;
}

.modalmodal {
    background-color: #ffffff;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    max-height: 80vh;
}

.modal-header {
    padding: 1.5rem 4rem 1.5rem 1.5rem;
    display: flex;
    background-color: #ffffff;
}

.modal-footer {
    padding: 1.5rem;
    background-color: #f7f7f9;
}

.modal-header {
    position: relative;
    border: none;
    justify-content: space-between;
}

.modal-body {
    position: relative;
    padding: 1.5rem;
}

.close-btn {
    position: absolute;
    top: 0;
    right: 2.5%;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    color: #232325;
    background: transparent;
}
</style>
