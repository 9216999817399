<template>
  <div>
    <span class="ml-5 font-11">
      <vs-dropdown vs-trigger-click>
        <a class="a-icon font-11" href="#" v-if="!select">
          {{ params.value }}
          <vs-icon class="" icon="expand_more"> </vs-icon>
        </a>
        <a class="a-icon font-11" href="#" v-else>
          {{ select }}
          <vs-icon class="" icon="expand_more"> </vs-icon>
        </a>
        <vs-dropdown-menu>
          <vs-dropdown-item
            v-for="(item, index) in choice"
            :key="index"
            :value="item.name"
            v-model="select"
            class="font-11 px-5"
            @click="clickAction(item)"
          >
            {{ item.name }}
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      choice: null,
      select: null
    };
  },
  mounted() {
    console.log(">>>>params", this.params.data);
    this.choice = this.params.data.dropdown_choice;
  },
  methods: {
    // openDropdown() {
    //   // console.log("params", this.params.data.choice);
    // },
    clickAction(item) {
      console.log(item);
      // axios.post(`${item.url}`,select)
      this.select = item.name;
      console.log("this.selcet", this.select, item);
    }
  },
  computed: {
    ...mapGetters({
      nowPage: "getNowPage"
    })
  }
};
</script>
<style>
.font-11 {
  font-size: 11px;
}
</style>
