<template>
  <div>
    <vs-button :color="bermuda">Column Setting</vs-button>
    <vs-button :color="bermuda">Add new admin</vs-button>
    <vs-button class="ml-3" :color="bermuda">Filter By</vs-button>
  </div>
</template>
<script>
import themeConfig from "@/../themeConfig.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      bermuda: themeConfig.theme.bermuda
    };
  },
  computed: {
    ...mapGetters({
      nowSlug: "getNowSlug"
    })
  },
  methods: {
    addNewAdmin() {
      this.$root.$emit("bv::show::modal", "modal-1");
    }
  },
  mounted() {
    console.log("nowSlug", this.nowSlug);
  }
};
</script>
<style lang="scss"></style>
