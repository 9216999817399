<template>
  <div>
    <div class="flex flex-wrap items-center ag-grid-table-actions-right">
      <div v-for="(item, index) in tab" :key="index">
        <vs-button
          class="ml-4 tab_label"
          :class="{ tabActive: item.isActive }"
          @click="changeTabActive(item)"
          v-if="item.isShow"
          >{{ item.tab_name }}</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      tab: "general/tab"
      // nowPage: "getNowPage"
    })
  },
  methods: {
    changeTabActive(index) {
      this.$store.dispatch("general/changeTabActive", {
        index: index,
        id: this.$route.params.id
      });
    }
  },

  updated() {
    // console.log("tab com", this.tab);
  },
  mounted() {
    console.log("Tab info", this.tab);
  }
};
</script>
<style lang="scss">
.tab_label {
  border-radius: 15px 15px 0px 0px;
  border: solid 1px #ffffff;
  border-bottom: 0px;
  background-color: #6A7E91 !important;
}
.tabActive {
  border: solid 1px #ffffff;
  border-bottom: 0px;
  background-color: var(--white) !important;
  color: #6A7E91 !important;
}
</style>
