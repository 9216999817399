<template>
  <div :style="`color: ${params.value.is_publish ? 'green' : 'red'};`">
    {{ isPublish }}
  </div>
</template>

<script>
import axios from "../../../../util/backend";
import { mapGetters } from "vuex";
import baseUrl from "../../../../util/baseUrl";

export default {
  computed: {
    isPublish: function () {
      return this.params.value.is_publish == true ? "Publish" : "Unpublish";
    },
    ...mapGetters({
      pointer: "general/pointer",
    }),
  },

  data() {
    return {
      status: null,

      // isActive: true,
      // hasError: false
    };
  },

  props: {
    is_publish: Object,
  },
};
</script>

<style lang="scss" scoped></style>
