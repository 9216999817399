<template>
  <div>
    <div @click="OpenModal" class="pointer">
      <u class="ml-5"> ดูรายละเอียด </u>
      <b-modal
        style="border-radius: 5px"
        content-class="modal-scoped"
        v-model="showModal"
        :hide-header="true"
        :hide-footer="true"
        size="ml"
        centered
      >
        <div class="close-icon w-full flex justify-end -mr-6">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="pointer"
            style="display: absolute; z-index: 1"
            @click="showModal = false"
          >
            <path
              d="M15.0012 0C6.71453 0 0 6.71563 0 15.0029C0 23.2852 6.71453 30.0004 15.0012 30.0004C23.2892 30.0004 30 23.2852 30 15.0029C30 6.71521 23.2892 0 15.0012 0ZM21.5609 19.3898C22.132 19.9796 21.9556 21.0787 21.1637 21.8465C20.3722 22.6143 19.268 22.7613 18.6956 22.1716L14.9569 18.3222L11.106 22.0606C10.5174 22.6317 9.41823 22.4528 8.65036 21.6626C7.88249 20.8724 7.7367 19.7684 8.32523 19.196L12.1762 15.4576L8.43747 11.607C7.86633 11.0185 8.04567 9.91814 8.83591 9.15033C9.6249 8.38377 10.7316 8.23758 11.3015 8.82523L15.0402 12.6755L18.8924 8.93871C19.4822 8.36637 20.5814 8.54528 21.348 9.33545C22.1171 10.1256 22.2616 11.2309 21.6731 11.8033L17.8234 15.5404L21.5609 19.3898Z"
              fill="#0430AD"
            />
          </svg>
        </div>
        <div class="container item-align-center mb-4">
          <!-- <template #default="{ close }"> -->
          <div>
            <h5 class="font-bold text-left" style="color: #4d4d4d">
              รายละเอียด
            </h5>
          </div>
          <div
            class="d-flex justify-center"
            style="margin-top: 20px; color: #264b8f"
          >
            <div class="detail-border w-100">
              <p class="font-bold ml-3 mt-3">{{ params.value }}</p>
            </div>
          </div>
        </div>
        <!-- <img src="@/assets/images/elements/alert.svg" alt="" />
          <br />
          <span>กรุณาตรวจสอบข้อมูลก่อนทำการลบ</span>
          <span class="font-bold">ต้องการลบข้อมูลหรือไม่ ?</span>
          <br /> -->

        <!-- <h5>กำลังโหลด</h5> -->
        <!-- <div>
            <b-button
              class="mr-5 cancel-btn"
              @click="close()"
              :disabled="isDisable"
              >ยกเลิก</b-button
            >
            <b-button
              class="ok-btn"
              @click="DeleteContent()"
              :disabled="isDisable"
              >ลบ</b-button
            >
          </div> -->
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "../../../../util/backend";
import baseUrl from "../../../../util/baseUrl";

export default {
  created() {},
  methods: {
    OpenModal() {
      switch (this.pointer) {
        case "curriculum":
        case "course":
          this.isDisable = false;
          this.showModalConfirm = true;
          break;
        default:
          this.isDisable = false;
          this.showModal = true;
          break;
      }
    },

    async DeleteContent() {
      if (this.showModalConfirm && this.confirmtext !== "Delete") {
        this.confirmtext = null;
        alert("ข้อความยืนยันการลบข้อมูลไม่ถูกต้อง");
      } else {
        this.$vs.loading({color: "#DDDDDD"});
        this.isDisable = true;
        console.log("delete");
        let config;
        switch (this.pointer) {
          case "curriculum":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteCurriculum`,
              data: {
                curriculum_id: this.params.value,
              },
            };
            await axios
              .api(config)
              .then((res) => {
                this.isDisable = false;
                this.showModalConfirm = false;
                this.$vs.loading.close();
                this.$store.dispatch("general/getAllCurriculum");
              })
              .catch((err) => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right",
                });
                this.isDisable = false;
              });
            break;

          case "course":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteCourse`,
              data: {
                course_id: this.params.value,
              },
            };
            await axios
              .api(config)
              .then((res) => {
                this.isDisable = false;
                this.showModalConfirm = false;
                this.$vs.loading.close();
                this.$store.dispatch(
                  "general/getCurriculumDetail",
                  this.$route.params.id
                );
              })
              .catch((err) => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right",
                });
                this.isDisable = false;
              });
            break;

          case "video":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteVideo`,
              data: {
                video_id: this.params.value,
              },
            };
            await axios
              .api(config)
              .then((res) => {
                this.isDisable = false;
                this.showModal = false;
                this.$vs.loading.close();
                this.$store.dispatch(
                  "general/getCourseDetail",
                  this.$route.params.id
                );
              })
              .catch((err) => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right",
                });
                this.isDisable = false;
              });
            break;

          case "organization":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteOrganization`,
              data: {
                organization_id: this.params.value,
              },
            };
            await axios
              .api(config)
              .then((res) => {
                this.isDisable = false;
                this.showModal = false;
                this.$vs.loading.close();
                this.$store.dispatch("general/getOrganization");
              })
              .catch((err) => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right",
                });
                this.isDisable = false;
              });

            break;

          case "curriculumType":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteCategory_curriculum`,
              data: {
                category_curriculum_id: this.params.value,
              },
            };
            await axios
              .api(config)
              .then((res) => {
                this.isDisable = false;
                this.showModal = false;
                this.$vs.loading.close();
                this.$store.dispatch("general/getcurriculumtype");
              })
              .catch((err) => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right",
                });
                this.isDisable = false;
              });
            break;

          case "banner":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteBanner`,
              data: {
                banner_id: this.params.value,
              },
            };
            await axios
              .api(config)
              .then((res) => {
                this.isDisable = false;
                this.showModal = false;
                this.$vs.loading.close();
                this.$store.dispatch("general/get_bannerTable");
              })
              .catch((err) => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right",
                });
                this.isDisable = false;
              });
            break;

          case "lecturer":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteLecturer`,
              data: {
                lecturer_id: this.params.value,
              },
            };
            await axios
              .api(config)
              .then((res) => {
                this.isDisable = false;
                this.showModal = false;
                this.$vs.loading.close();
                this.$store.dispatch("general/get_LecturerTable");
              })
              .catch((err) => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right",
                });
                this.isDisable = false;
              });
            break;

          default:
            this.isDisable = false;
            break;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      pointer: "general/pointer",
    }),
  },
  updated() {
    // console.log("nowPage", this.nowPage);
  },
  mounted() {},
  data() {
    return {
      showModal: false,
      showModalConfirm: true,
      confirmtext: null,
      isDisable: false,
    };
  },
};
</script>

<style lang="scss" scoped>
/deep/ .modal-scoped {
  font-family: 'Montserrat', 'Athiti', sans-serif;
  // justify-content: center;
}

// /deep/ .modal-style {
//   margin: 1rem !important;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   // justify-content: center;
//   margin: 10px;
// }

.detail-modal {
  color: #4d4d4d;
}

.cancel-btn {
  border: none;
  background-color: #c4c4c4;
  color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem 2rem;
}

.ok-btn {
  border: none;
  background-color: #305eb3;
  color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem 3rem;
}

.detail-border {
  width: 400px;
  height: 200px;
  background: #ffffff;
  border: 1px solid #979aa1;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: scroll;
  // margin-bottom: 3px;
}
</style>
