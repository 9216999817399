<template>
  <div id="ag-grid-demo">
    <!-- <p>{{ tab }}</p> -->
    <!-- {{ activePage }}-->

    <div>
      <div
        class="ag-grid-table-actions-right"
        v-if="
          page.table.add_button.value || page.breadcrumb || pointer !== 'banner'
        "
      >
        <div
          class="flex items-center justify-between mb-8"
          v-bind:class="{ fixjustify: !page.table.add_button.value }"
        >
          <vs-button
            :color="bermuda"
            v-if="page.table.filter_button.value"
            class="mr-3"
            @click="openModal()"
            >Filter</vs-button
          >
          <router-link
            v-if="this.$route.params.id"
            :to="page.table.add_button.to + '/' + this.$route.params.id"
          >
            <vs-button
              :color="bermuda"
              class="add-button"
              v-if="page.table.add_button.value"
              >{{ page.table.add_button.name }}</vs-button
            >
          </router-link>
          <router-link v-else-if="Showaddbutton" :to="page.table.add_button.to">
            <vs-button :color="bermuda" class="add-button">{{
              page.table.add_button.name
            }}</vs-button>
          </router-link>

          <div class="font-semibold tablename" v-if="page.breadcrumb">
            {{ page.breadcrumb.name }}
          </div>
          <vs-input
            v-if="pointer !== 'banner' &&  pointer !== 'admin' &&  pointer !== 'manageproduct'
              &&  pointer !== 'managehome' &&  pointer !== 'manageproductClean'" 
            class="mb-5 md:mb-0 mr-4 search-table"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search"
            icon="search"
          />
        </div>
      </div>

      <div class="h-full">
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div
            v-if="page.breadcrumb"
            class="mb-8 xs:mb-0 mr-4 ag-grid-table-actions-left"
          >
            <router-link :to="activePage.url">{{
              activePage.name
            }}</router-link>
            <span v-if="page.breadcrumb">
              >
              <router-link :to="page.breadcrumb.url">{{
                page.breadcrumb.name
              }}</router-link></span
            >
            <span v-if="page.subtable">
              >
              {{ page.subtable.course }}
            </span>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        </div>

        <div v-if="page.subtable" class="tablestyle">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">รหัสคอร์ส</th>
                <th scope="col">ชื่อคอร์ส</th>
                <th scope="col">จำนวนวีดีโอของคอร์ส</th>
                <th scope="col">การเผยเเพร่</th>
                <th scope="col">จัดการ</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ page.subtable.course_code }}</td>
                <td>{{ page.subtable.course }}</td>
                <td>{{ page.subtable.total_video }}</td>
                <td><TogglePublish :is_public="page.subtable.publish" /></td>
                <td><Edit :id="page.subtable.edit" /></td>
              </tr>
            </tbody>
          </table>
        </div>

        <tab-component></tab-component>
        <ag-grid-vue
          ref="agGridTable"
          style="height:60vh"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 ag-grid-table"
          :columnDefs="page.table.column"
          :defaultColDef="defaultColDef"
          :rowData="UpdateData"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :enableRtl="$vs.rtl"
          @first-data-rendered="onFirstDataRendered"
          :setQuickFilter="updateSearchQuery"
          @gridReady="onGridReady"
          :overlayLoadingTemplate="overlayLoadingTemplate"
        >
          <!-- 
          :pagination="true"
          :suppressPaginationPanel="true"
          :paginationPageSize="paginationPageSize"
          @rowDataChanged="onFirstDataRendered" -->
        </ag-grid-vue>
        <div class="flex justify-between mt-3">
          <router-link v-if="pointer == 'banner'" :to="{ path: '/addbanner' }">
            <vs-button :color="bermuda" class="add-banner"
              >เพิ่มรูปภาพ Banner</vs-button
            >
          </router-link>
          <!-- <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        /> -->
        </div>
      </div>
    </div>
    <div>
      <!-- <b-button v-b-modal.modal-1>Launch demo modal</b-button> -->

      <b-modal
        id="modal-1"
        center
        style="margin-top: 40%;"
        title="Add new admin"
        modal-header="No"
      >
        <b-row class="py-3">
          <b-col cols="3" class="text-center mt-1"> Username</b-col>
          <b-col cols="9" class="text-left">
            <b-form-input
              v-model="username"
              placeholder="Username"
            ></b-form-input
          ></b-col>
        </b-row>
        <b-row class="py-3">
          <b-col cols="3" class="text-center mt-1"> Password</b-col>
          <b-col cols="9" class="text-left">
            <b-form-input
              v-model="password"
              placeholder="Password"
            ></b-form-input
          ></b-col>
        </b-row>
        <b-row class="py-3">
          <b-col cols="3" class="text-center mt-1"> Name</b-col>
          <b-col cols="9" class="text-left">
            <b-form-input v-model="name" placeholder="Name"></b-form-input
          ></b-col>
        </b-row>
        <b-row class="py-3">
          <b-col cols="3" class="text-center mt-1"> Role</b-col>
          <b-col cols="9" class="text-left">
            <b-form-input v-model="role" placeholder="Role"></b-form-input
          ></b-col>
        </b-row>
        <template #modal-footer="{ ok, cancel }">
          <!-- <b>Custom Footer</b> -->
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button class="btn-primary" @click="ok()">
            ตกลง
          </b-button>
          <b-button class="btn-gery" @click="cancel()">
            ยกเลิก
          </b-button>
          <!-- Button with custom close trigger value -->
        </template>

        <!-- <p class="my-4">Hello from modal!</p> -->
      </b-modal>
    </div>
    <b-modal id="filter_button" title="Filter">
      <vs-row>
        <vs-col vs-w="6">
          <vs-select label="Column" v-model="columnFilter">
            <vs-select-item
              :key="index"
              placeholder="Choose"
              :text="item.name"
              :value="item"
              v-for="(item, index) in page.table.filter_button.column"
            /> </vs-select
        ></vs-col>
        <vs-col vs-w="6">
          <vs-select
            class="selectExample"
            label="Filter"
            v-model="selectFilter"
            v-if="columnFilter"
          >
            <vs-select-item
              :key="index"
              placeholder="Choose"
              :text="item"
              :value="item"
              v-for="(item, index) in columnFilter.filter"
            /> </vs-select
        ></vs-col>
      </vs-row>
      <!-- <p class="my-4">Vertically centered modal!</p> -->
    </b-modal>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import topButton from "../topTableButton/topTableButton";
import { mapGetters } from "vuex";
import iconDetail from "./table_component/iconDetail";
import iconDelete from "./table_component/iconDelete";
import tabComponent from "./../tabComponent/tabComponent";
import Dropdown from "./table_component/Dropdown";
import String from "./table_component/String";
import Date from "./table_component/Date";
import Float from "./table_component/Float";
import Number from "./table_component/Number";
import Array from "./table_component/Array";
import Publish from "./table_component/Publish";
import Edit from "./table_component/Edit";
import EditProduct from "./table_component/EditProduct";
// import CreateProduct from "./../../elements/";
import Delete from "./table_component/Delete";
import Detail from "./table_component/Detail.vue";
import Datepicker from "vuejs-datepicker";
import TogglePublish from "./table_component/TogglePublish.vue";
import moment from "moment";
import iconUp from "./table_component/iconUp.vue";
import iconDown from "./table_component/iconDown.vue";
import FormatTime from "./table_component/FormatTime.vue";

import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import themeConfig from "@/../themeConfig.js";

export default {
  beforeMount() {
    this.overlayLoadingTemplate =
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  },
  components: {
    AgGridVue,
    Datepicker,
    iconDetail,
    iconDelete,
    tabComponent,
    topButton,
    Dropdown,
    String,
    Date,
    Float,
    Number,
    Publish,
    Array,
    Edit,
    EditProduct,
    // CreateProduct,
    Delete,
    Detail,
    TogglePublish,
    iconUp,
    iconDown,
    FormatTime
  },
  data() {
    return {
      bermuda: null,
      searchQuery: "",
      gridOptions: { resizable: true },
      maxPageNumbers: 7,
      gridApi: "sizeColumnsToFit",
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      start_date: null,
      end_date: null,
      masks: {
        input: "YYYY-MM-DD"
      },
      username: null,
      password: null,
      name: null,
      role_choice: ["Admin", "Super Admin"],
      role: null,
      columnFilter: null,
      selectFilter: null,
      overlayLoadingTemplate: null
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    }
  },
  computed: {
    ...mapGetters({
      //getTable: "general/table",
      //nowPage: "getNowPage",
      activePage: "general/activePage",
      page: "general/page",
      pointer: "general/pointer"
    }),
    Showaddbutton() {
      if (
        this.page.table.add_button.value === true &&
        this.pointer !== "banner"
      ) {
        console.log("check");
        return true;
      }
    },

    start_date_moment() {
      // console.log("start_date", this.start_date);
      const start_date = moment().format("YYYY-MM-01");
      return start_date;
    },
    end_date_moment() {
      // console.log("his.end_date", this.end_date);
      const end_date = moment().format("YYYY-MM-") + moment().daysInMonth();

      return end_date;
    },

    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 25;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    },
    UpdateData() {
      console.log(">>>>", this.page.table.data);
      return this.page.table.data;
    },
    HeightDynamic() {
      return this.Height;
    }
  },
  methods: {
    updateSearchQuery(val) {
      console.log("val:", val);
      this.gridApi.setQuickFilter(val);
    },
    changeTabActive(index) {
      this.$store.dispatch("home/changeTabActive", index);
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
    },
    openModal() {
      this.$bvModal.show("filter_button");
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.bermuda = themeConfig.theme.bermuda;
  }
};
</script>
<style>
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  outline: 0;
}

.search-table .vs-con-input .vs-inputx {
  line-height: 1.5;
  border: none !important;
}

.search-table .vs-con-input .material-icons {
  line-height: 1.5;
}

.add-button {
  border-radius: 10px;
  background-color: #0430ad !important;
  font-family: 'Montserrat', 'Athiti', sans-serif;;
}

.add-banner {
  border-radius: 10px;
  color: #0430ad;
  background-color: #ffffff !important;
  font-family: 'Montserrat', 'Athiti', sans-serif;;
  width: max-content;
}

.tablestyle {
  background: white;
}

.table th {
  color: #ffffff;
  vertical-align: middle;
  border-bottom: 1px solid black !important;
}
.table-bordered th {
  border-top: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  border-bottom: 1px solid black !important;
}
.table-bordered td {
  border-top: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  border-bottom: 1px solid #fff !important;
}
.table-bordered {
  border: 0 !important;
}

.fixjustify {
  display: flex !important;
  justify-content: end !important;
}

.tablename {
  font-size: 1.125rem;
}
</style>
