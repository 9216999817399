<template>
  <div class="p-1 flex">
    <!-- <span>{{ params.value}}</span> -->

    <div v-for="(item, index) in params.value" :key="index">
      <p v-if="index === 0">{{ item }}</p>
      <p v-else>, {{ item }}</p>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  mounted() {
    // console.log("params aaray", this.params.value);
  },
  methods: {},
};
</script>
