<template>
  <div>
    <div @click="OpenModal" class="pointer">
      <span class="text-primary">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.6641 12C21.3988 12 21.1445 12.1054 20.957 12.2929C20.7694 12.4804 20.6641 12.7348 20.6641 13V19C20.6641 19.2652 20.5587 19.5196 20.3712 19.7071C20.1836 19.8946 19.9293 20 19.6641 20H5.66406C5.39885 20 5.14449 19.8946 4.95696 19.7071C4.76942 19.5196 4.66406 19.2652 4.66406 19V5C4.66406 4.73478 4.76942 4.48043 4.95696 4.29289C5.14449 4.10536 5.39885 4 5.66406 4H11.6641C11.9293 4 12.1836 3.89464 12.3712 3.70711C12.5587 3.51957 12.6641 3.26522 12.6641 3C12.6641 2.73478 12.5587 2.48043 12.3712 2.29289C12.1836 2.10536 11.9293 2 11.6641 2H5.66406C4.86841 2 4.10535 2.31607 3.54274 2.87868C2.98013 3.44129 2.66406 4.20435 2.66406 5V19C2.66406 19.7956 2.98013 20.5587 3.54274 21.1213C4.10535 21.6839 4.86841 22 5.66406 22H19.6641C20.4597 22 21.2228 21.6839 21.7854 21.1213C22.348 20.5587 22.6641 19.7956 22.6641 19V13C22.6641 12.7348 22.5587 12.4804 22.3712 12.2929C22.1836 12.1054 21.9293 12 21.6641 12ZM6.66406 12.76V17C6.66406 17.2652 6.76942 17.5196 6.95696 17.7071C7.14449 17.8946 7.39885 18 7.66406 18H11.9041C12.0357 18.0008 12.1661 17.9755 12.288 17.9258C12.4098 17.876 12.5206 17.8027 12.6141 17.71L19.5341 10.78L22.3741 8C22.4678 7.90704 22.5422 7.79644 22.593 7.67458C22.6437 7.55272 22.6699 7.42201 22.6699 7.29C22.6699 7.15799 22.6437 7.02728 22.593 6.90542C22.5422 6.78356 22.4678 6.67296 22.3741 6.58L18.1341 2.29C18.0411 2.19627 17.9305 2.12188 17.8086 2.07111C17.6868 2.02034 17.5561 1.9942 17.4241 1.9942C17.2921 1.9942 17.1613 2.02034 17.0395 2.07111C16.9176 2.12188 16.807 2.19627 16.7141 2.29L13.8941 5.12L6.95406 12.05C6.86138 12.1434 6.78806 12.2543 6.73829 12.3761C6.68853 12.4979 6.6633 12.6284 6.66406 12.76ZM17.4241 4.41L20.2541 7.24L18.8341 8.66L16.0041 5.83L17.4241 4.41ZM8.66406 13.17L14.5941 7.24L17.4241 10.07L11.4941 16H8.66406V13.17Z"
            fill="#0430AD"
          />
        </svg>
      </span>
    </div>
    <b-modal
      style="border-radius: 5px"
      content-class="modal-scoped"
      body-class="modal-style"
      v-model="showModal"
      :hide-header="true"
      :hide-footer="true"
      size="xl"
      :style="{ top: 0 }"
      centered
    >
      <div class="close-icon w-full flex justify-end -mr-6">
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="pointer"
          style="display: absolute; z-index: 1"
          @click="showModal = false"
        >
          <path
            d="M15.0012 0C6.71453 0 0 6.71563 0 15.0029C0 23.2852 6.71453 30.0004 15.0012 30.0004C23.2892 30.0004 30 23.2852 30 15.0029C30 6.71521 23.2892 0 15.0012 0ZM21.5609 19.3898C22.132 19.9796 21.9556 21.0787 21.1637 21.8465C20.3722 22.6143 19.268 22.7613 18.6956 22.1716L14.9569 18.3222L11.106 22.0606C10.5174 22.6317 9.41823 22.4528 8.65036 21.6626C7.88249 20.8724 7.7367 19.7684 8.32523 19.196L12.1762 15.4576L8.43747 11.607C7.86633 11.0185 8.04567 9.91814 8.83591 9.15033C9.6249 8.38377 10.7316 8.23758 11.3015 8.82523L15.0402 12.6755L18.8924 8.93871C19.4822 8.36637 20.5814 8.54528 21.348 9.33545C22.1171 10.1256 22.2616 11.2309 21.6731 11.8033L17.8234 15.5404L21.5609 19.3898Z"
            fill="#0430AD"
          />
        </svg>
      </div>
      <div class="container item-align-center">
        <!-- <template #default="{ close }"> -->
        <h5 class="font-bold text-left" style="color: #4d4d4d">
          จัดการข้อความ banner
        </h5>
        <br />
        <div class="detail-border">
          <div class="mb-5">
            <div
              class="uploadfield pointer"
              @dragover.prevent
              @drop.prevent
              @click="onPickCourseImg"
            >
              <div
                @drop="dragCourseImg"
                class="flex items-center justify-center h-full"
              >
                <img
                  v-if="img_url"
                  class="h-full w-full object-contain"
                  :src="img_url"
                />
                <img v-show="!img_url" :src="img_url" class="img-upload" />
              </div>
            </div>
            <input
              type="file"
              accept="image/*"
              style="display: none"
              ref="CourseImgInput"
              @change="uploadCourseImg"
            />
          </div>
          <div>
            <div class="flex justify-between">
              <vs-input
                class="w-full mb-5"
                type="text"
                id="header"
                placeholder="ชื่อแบนเนอร์"
                v-model="title"
                required
              />
            </div>
            <div class="flex justify-between mb-5">
              <div class="w-3/5 mr-3">
                <b-row>
                  <b-col md="2" class="ml-auto md-2 mb-2"> TH </b-col>
                </b-row>
                <vs-input
                  class="w-full mb-5"
                  type="text"
                  id="header"
                  placeholder="ชื่อหัวข้อ"
                  v-model="topic_th"
                  required
                />

                <vs-textarea
                  placeholder="ข้อความรายละเอียด 1"
                  height="150px"
                  v-model="detail_th"
                  required
                />
              </div>

              <div class="w-3/5 ml-3">
                <b-row>
                  <b-col md="2" class="ml-auto md-2 mb-2"> EN </b-col>
                </b-row>
                <vs-input
                  class="w-full mb-5"
                  type="text"
                  id="header"
                  placeholder="Topic name"
                  v-model="topic_en"
                  required
                />

                <vs-textarea
                  placeholder="Text detail 1"
                  height="150px"
                  v-model="detail_en"
                  required
                />
              </div>
            </div>
            <div class="flex justify-end mt-5 mb-5">
              <button
                class="del-btn mr-3"
                @click="$refs[params.value.banner_id].show()"
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.25 2.125H10.5V1.59375C10.5 0.713535 9.82844 0 9 0H7C6.17156 0 5.5 0.713535 5.5 1.59375V2.125H2.75C2.05966 2.125 1.5 2.71963 1.5 3.45312V4.51562C1.5 4.80904 1.72384 5.04688 2 5.04688H14C14.2762 5.04688 14.5 4.80904 14.5 4.51562V3.45312C14.5 2.71963 13.9403 2.125 13.25 2.125ZM6.5 1.59375C6.5 1.3009 6.72437 1.0625 7 1.0625H9C9.27563 1.0625 9.5 1.3009 9.5 1.59375V2.125H6.5V1.59375Z"
                    fill="white"
                  />
                  <path
                    d="M2.44885 6.10938C2.35963 6.10938 2.28853 6.1886 2.29278 6.28329L2.70528 15.482C2.74341 16.3333 3.40153 17 4.20341 17H11.7965C12.5984 17 13.2565 16.3333 13.2947 15.482L13.7072 6.28329C13.7114 6.1886 13.6403 6.10938 13.5511 6.10938H2.44885ZM9.99997 7.4375C9.99997 7.14398 10.2237 6.90625 10.5 6.90625C10.7762 6.90625 11 7.14398 11 7.4375V14.3438C11 14.6373 10.7762 14.875 10.5 14.875C10.2237 14.875 9.99997 14.6373 9.99997 14.3438V7.4375ZM7.49997 7.4375C7.49997 7.14398 7.72372 6.90625 7.99997 6.90625C8.27622 6.90625 8.49997 7.14398 8.49997 7.4375V14.3438C8.49997 14.6373 8.27622 14.875 7.99997 14.875C7.72372 14.875 7.49997 14.6373 7.49997 14.3438V7.4375ZM4.99997 7.4375C4.99997 7.14398 5.22372 6.90625 5.49997 6.90625C5.77622 6.90625 5.99997 7.14398 5.99997 7.4375V14.3438C5.99997 14.6373 5.77622 14.875 5.49997 14.875C5.22372 14.875 4.99997 14.6373 4.99997 14.3438V7.4375Z"
                    fill="white"
                  />
                </svg>
              </button>
              <button
                class="save-btn"
                type="submit"
                value="Submit"
                @click="saveBtn"
                :disabled="isDisabled"
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      :ref="params.value.banner_id"
      content-class="modal-scoped"
      :hide-header="true"
      :hide-footer="true"
      centered
    >
      <div class="py-6">
        <div class="text-center text-xl font-semibold">
          คุณต้องการลบรายการนี้ใช่หรือไม่
        </div>
        <div class="mt-6 flex justify-center gap-6">
          <button
            class="rounded-full px-6 cancle-btn"
            @click="$refs[params.value.banner_id].hide()"
          >
            ยกเลิก
          </button>
          <button
            class="rounded-full px-6 save-btn"
            :disabled="disabledBtn"
            @click="disabledBtn ? '':delBanner()"
          >
            ยืนยัน
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "../../../../util/backend";
import baseUrl from "../../../../util/baseUrl";
import normAxios from "axios";

export default {
  created() {
    (this.title = this.params.value.title),
      (this.topic_th = this.params.value.topic_th),
      (this.topic_en = this.params.value.topic_en),
      (this.detail_th = this.params.value.detail_th),
      (this.detail_en = this.params.value.detail_en);
    this.img_url = this.params.value.url;
  },
  methods: {
    async saveBtn() {
      this.isDisabled = true;
      let config = {
        method: "put",
        url: `${baseUrl.baseurl()}/api/oil/updateBanner`,
        data: {
          banner_id: this.params.value.banner_id,
          title: this.title,
          topic_th: this.topic_th,
          topic_en: this.topic_en,
          detail_th: this.detail_th,
          detail_en: this.detail_en,
          url: this.img_url,
        },
      };

      await axios
        .api(config)
        .then((res) => {
          this.$store.dispatch("general/get_AdminTable");
          this.$vs.notify({
            title: "สำเร็จ",
            text: `บันทึกสำเร็จ`,
            color: "success",
            icon: "check",
            position: "top-right",
          });
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error message",
            text: `${err.response.data.error ? err.response.data.error : err}`,
            color: "danger",
            icon: "error",
            position: "top-right",
          });
        });
      setTimeout(() => {
        this.disabledBtn = false;
      }, 3000);
    },

    delBanner() {
      this.disabledBtn = true;
      let config = {
        method: "delete",
        url: `${baseUrl.baseurl()}/api/oil/deleteBanner/${
          this.params.value.banner_id
        }`,
      };

      axios
        .api(config)
        .then((res) => {
          this.$store.dispatch("general/get_AdminTable");
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Error message",
            text: `${err.response.data.error ? err.response.data.error : err}`,
            color: "danger",
            icon: "error",
            position: "top-right",
          });
        });
      setTimeout(() => {
        this.disabledBtn = false;
      }, 3000);
    },

    OpenModal() {
      switch (this.pointer) {
        case "curriculum":
        case "course":
          this.isDisable = false;
          this.showModalConfirm = true;
          break;
        default:
          this.isDisable = false;
          this.showModal = true;
          this.showModal_del = true;
          break;
      }
    },

    async uploadFile(file) {
      let fd = new FormData();
      fd.append("file", file);
      fd.append("folder_id", 1);
      try {
        let res = await normAxios.post(
          "https://vulcan.houseofdev.tech/file-management-system/api/file/create",
          fd
        );
        console.log(res);
        if (res.status == 200) {
          return res.data.data.uploadFileAtLevel.url;
        } else {
          alert(res);
        }
      } catch (error) {
        alert(error);
      }
    },

    //////Course Image///////
    onPickCourseImg() {
      console.log("click!!!!!!!");
      this.$refs.CourseImgInput.click();
    },
    async dragCourseImg(e) {
      console.log("file drag", e.dataTransfer.files[0]);
      this.img_url = await this.uploadFile(e.dataTransfer.files[0]);
    },
    async uploadCourseImg(e) {
      console.log("file click", e.target.files[0]);
      this.img_url = await this.uploadFile(e.target.files[0]);
    },
  },
  computed: {
    ...mapGetters({
      activePage: "general/activePage",
      pointer: "general/pointer",
    }),
  },
  data() {
    return {
      // course_id: null,
      disabledBtn: false,
      isDisabled: false,
      img_url: null,
      title: null,
      topic_th: null,

      topic_en: null,
      detail_th: null,
      detail_en: null,

      courseImg: null,
      courseImg1: null,

      showModal: false,
      showModal_del: false,
      showModalConfirm: true,
      confirmtext: null,
      isDisable: false,
    };
  },
  updated() {
    // console.log("nowPage", this.nowPage);
  },
  props: {
    id: Number,
  },
};
</script>

<style lang="scss" scoped>
.save-btn {
  background-color: #0430ad;
  padding: 10px 50px 10px 50px;
  border-radius: 20px;
  border-style: none;
  color: #ffff;

  &:hover {
    transition-delay: 100ms;
    background-color: #072c49;
  }
  &:active {
    background-color: #0430ad;
  }
  &:disabled {
    background-color: #072c49;
  }
}

.del-btn {
  background: #d14842;
  padding: 10px 35px 10px 35px;
  border-radius: 20px;
  border-style: none;
  color: #ffff;

  &:hover {
    transition-delay: 100ms;
    background-color: #521515;
  }
  &:active {
    background: #d14842;
  }
}

.cancle-btn {
  background: #bdbdbd;
  padding: 10px 35px 10px 35px;
  border-radius: 20px;
  border-style: none;
  color: #ffff;

  &:hover {
    transition-delay: 100ms;
    background-color: #8b8b8b;
  }
  &:active {
    background: #bdbdbd;
  }
}

.img-upload {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/deep/ .modal-scoped {
  font-family: 'Montserrat', 'Athiti', sans-serif;
  // justify-content: center;
}
.uploadfield{
  height: 260px;
}
</style>
