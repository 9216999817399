<template>
  <div>
    {{ secToMin(params.value) }}
  </div>
</template>

<script>
import { secToMin } from "../../../../util/convertTime";
export default {
  methods: {
    secToMin
  }
};
</script>

<style lang="scss" scoped></style>
