<template>
  <div>
    <div v-if="this.params.value == null">
      ไม่ได้ตั้งเวลา
    </div>
    {{ date }}
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      date: new Date()
    };
  },
  mounted() {
    this.date = moment(this.params.value).format("DD/MM/YYYY");
  },
  methods: {}
};
</script>
