<template>
  <div class="flex items-center h-full">
    <button @click="GotoDetailPage()" class="button-view text-center">
      View
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    async GotoDetailPage() {
      // console.log("campaign_id:",this.$route.name, campaign_id );
      if (this.$route.name === "campaign") {
        this.$router.push({
          name: "campaign_detail",
          params: { campaign_id: this.params.value.campaign_id },
        });
      } else if (this.$route.name === "campaign_detail") {
        this.$router.push({
          name: "brand_detail",
          params: {
            campaign_id: this.$route.params.campaign_id,
            brand_id: this.params.value.brand_id,
          },
        });
      } else if (this.$route.name === "catalog") {
        this.$router.push({
          name: "catalog_detail",
          params: { catalog_id: this.params.value.catalog_id, page: 1 },
        });
      }
    },
  },
  computed: {},

  data() {
    return {
      path: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.button-view {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6a7e91;
  background-color: #c2d7e5;
  border-radius: 5px;
  border: none;
  padding: 0rem 1rem;
  height: 2rem;
}
</style>
