<template>
  <div>
    <div @click="OpenModal"  class="pointer">
      <img src="@/assets/images/elements/delete.svg" alt="" />
      <b-modal
        content-class="modal-scoped"
        body-class="modal-style"
        v-model="showModal"
        :hide-header="true"
        :hide-footer="true"
        size="sm"
      >
        <template #default="{ close }">
          <h5 class="font-bold">แจ้งเตือน !</h5>
          <br />
          <img src="@/assets/images/elements/alert.svg" alt="" />
          <br />
          <span>กรุณาตรวจสอบข้อมูลก่อนทำการลบ</span>
          <span class="font-bold">ต้องการลบข้อมูลหรือไม่ ?</span>
          <br />
          <!-- <h5>กำลังโหลด</h5> -->
          <div>
            <b-button
              class="mr-5 cancel-btn"
              @click="close()"
              :disabled="isDisable"
              >ยกเลิก</b-button
            >
            <b-button
              class="ok-btn"
              @click="DeleteContent()"
              :disabled="isDisable"
              >ลบ</b-button
            >
          </div>
        </template>
      </b-modal>

      <b-modal
        content-class="modal-scoped"
        body-class="modal-style"
        v-model="showModalConfirm"
        :hide-header="true"
        :hide-footer="true"
        size="sm"
      >
        <template #default="{ close }">
          <h5 class="font-bold">แจ้งเตือน !</h5>
          <br />
          <img src="@/assets/images/elements/alert.svg" alt="" />
          <br />
          <span
            >กรุณาใส่ข้อความ “Delete” <br />
            และกดปุ่มลบ เพื่อลบข้อมูล</span
          >
          <span class="font-bold"
            >เมื่อข้อมูลถูกลบแล้ว จะไม่สามารถกู้คืนได้</span
          >
          <br />
          <span>กรอกข้อความยืนยันการลบข้อมูล</span>
          <b-form-input
            class="w-1/2 text-center"
            placeholder="กรอกข้อความ"
            v-model="confirmtext"
          ></b-form-input>
          <br />
          <div>
            <b-button class="mr-5 cancel-btn" @click="close()">ยกเลิก</b-button>
            <b-button
              class="ok-btn"
              @click="DeleteContent()"
              :disabled="isDisable"
              >ลบ</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
    <div>
      <img src="@/assets/images/elements/gray_delete.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "../../../../util/backend";
import baseUrl from "../../../../util/baseUrl";

export default {
  created() {},
  methods: {
    OpenModal() {
      switch (this.pointer) {
        case "curriculum":
        case "course":
          this.isDisable = false;
          this.showModalConfirm = true;
          break;
        default:
          this.isDisable = false;
          this.showModal = true;
          break;
      }
    },

    async DeleteContent() {
      if (this.showModalConfirm && this.confirmtext !== "Delete") {
        this.confirmtext = null;
        alert("ข้อความยืนยันการลบข้อมูลไม่ถูกต้อง");
      } else {
        this.$vs.loading({color: "#DDDDDD"});
        this.isDisable = true;
        console.log("delete");
        let config;
        switch (this.pointer) {
          case "curriculum":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteCurriculum`,
              data: {
                curriculum_id: this.params.value
              }
            };
            await axios
              .api(config)
              .then(res => {
                this.isDisable = false;
                this.showModalConfirm = false;
                this.$vs.loading.close();
                this.$store.dispatch("general/getAllCurriculum");
              })
              .catch(err => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right"
                });
                this.isDisable = false;
              });
            break;

          case "course":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteCourse`,
              data: {
                course_id: this.params.value
              }
            };
            await axios
              .api(config)
              .then(res => {
                this.isDisable = false;
                this.showModalConfirm = false;
                this.$vs.loading.close();
                this.$store.dispatch(
                  "general/getCurriculumDetail",
                  this.$route.params.id
                );
              })
              .catch(err => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right"
                });
                this.isDisable = false;
              });
            break;

          case "video":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteVideo`,
              data: {
                video_id: this.params.value
              }
            };
            await axios
              .api(config)
              .then(res => {
                this.isDisable = false;
                this.showModal = false;
                this.$vs.loading.close();
                this.$store.dispatch(
                  "general/getCourseDetail",
                  this.$route.params.id
                );
              })
              .catch(err => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right"
                });
                this.isDisable = false;
              });
            break;

          case "organization":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteOrganization`,
              data: {
                organization_id: this.params.value
              }
            };
            await axios
              .api(config)
              .then(res => {
                this.isDisable = false;
                this.showModal = false;
                this.$vs.loading.close();
                this.$store.dispatch("general/getOrganization");
              })
              .catch(err => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right"
                });
                this.isDisable = false;
              });

            break;

          case "curriculumType":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteCategory_curriculum`,
              data: {
                category_curriculum_id: this.params.value
              }
            };
            await axios
              .api(config)
              .then(res => {
                this.isDisable = false;
                this.showModal = false;
                this.$vs.loading.close();
                this.$store.dispatch("general/getcurriculumtype");
              })
              .catch(err => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right"
                });
                this.isDisable = false;
              });
            break;

          case "banner":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteBanner`,
              data: {
                banner_id: this.params.value
              }
            };
            await axios
              .api(config)
              .then(res => {
                this.isDisable = false;
                this.showModal = false;
                this.$vs.loading.close();
                this.$store.dispatch("general/get_bannerTable");
              })
              .catch(err => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right"
                });
                this.isDisable = false;
              });
            break;

          case "lecturer":
            config = {
              method: "delete",
              url: `${baseUrl.baseurl()}/deleteLecturer`,
              data: {
                lecturer_id: this.params.value
              }
            };
            await axios
              .api(config)
              .then(res => {
                this.isDisable = false;
                this.showModal = false;
                this.$vs.loading.close();
                this.$store.dispatch("general/get_LecturerTable");
              })
              .catch(err => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error message",
                  text: `${
                    err.response.data.error ? err.response.data.error : err
                  }`,
                  color: "danger",
                  icon: "error",
                  position: "top-right"
                });
                this.isDisable = false;
              });
            break;

          default:
            this.isDisable = false;
            break;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      pointer: "general/pointer"
    })
  },
  updated() {
    // console.log("nowPage", this.nowPage);
  },
  mounted() {},
  data() {
    return {
      showModal: false,
      showModalConfirm: true,
      confirmtext: null,
      isDisable: false
    };
  }
};
</script>

<style lang="scss" scoped>
/deep/ .modal-scoped {
  font-family: 'Montserrat', 'Athiti', sans-serif;;
  background-color: #ffffff;
  color: #305eb3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/deep/ .modal-style {
  margin: 1rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.cancel-btn {
  border: none;
  background-color: #c4c4c4;
  color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem 2rem;
}

.ok-btn {
  border: none;
  background-color: #305eb3;
  color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem 3rem;
}
</style>
