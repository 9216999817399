<template>
  <div>
    <div class="pointer">
      <span class="text-primary" @click="goToPage">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.6641 12C21.3988 12 21.1445 12.1054 20.957 12.2929C20.7694 12.4804 20.6641 12.7348 20.6641 13V19C20.6641 19.2652 20.5587 19.5196 20.3712 19.7071C20.1836 19.8946 19.9293 20 19.6641 20H5.66406C5.39885 20 5.14449 19.8946 4.95696 19.7071C4.76942 19.5196 4.66406 19.2652 4.66406 19V5C4.66406 4.73478 4.76942 4.48043 4.95696 4.29289C5.14449 4.10536 5.39885 4 5.66406 4H11.6641C11.9293 4 12.1836 3.89464 12.3712 3.70711C12.5587 3.51957 12.6641 3.26522 12.6641 3C12.6641 2.73478 12.5587 2.48043 12.3712 2.29289C12.1836 2.10536 11.9293 2 11.6641 2H5.66406C4.86841 2 4.10535 2.31607 3.54274 2.87868C2.98013 3.44129 2.66406 4.20435 2.66406 5V19C2.66406 19.7956 2.98013 20.5587 3.54274 21.1213C4.10535 21.6839 4.86841 22 5.66406 22H19.6641C20.4597 22 21.2228 21.6839 21.7854 21.1213C22.348 20.5587 22.6641 19.7956 22.6641 19V13C22.6641 12.7348 22.5587 12.4804 22.3712 12.2929C22.1836 12.1054 21.9293 12 21.6641 12ZM6.66406 12.76V17C6.66406 17.2652 6.76942 17.5196 6.95696 17.7071C7.14449 17.8946 7.39885 18 7.66406 18H11.9041C12.0357 18.0008 12.1661 17.9755 12.288 17.9258C12.4098 17.876 12.5206 17.8027 12.6141 17.71L19.5341 10.78L22.3741 8C22.4678 7.90704 22.5422 7.79644 22.593 7.67458C22.6437 7.55272 22.6699 7.42201 22.6699 7.29C22.6699 7.15799 22.6437 7.02728 22.593 6.90542C22.5422 6.78356 22.4678 6.67296 22.3741 6.58L18.1341 2.29C18.0411 2.19627 17.9305 2.12188 17.8086 2.07111C17.6868 2.02034 17.5561 1.9942 17.4241 1.9942C17.2921 1.9942 17.1613 2.02034 17.0395 2.07111C16.9176 2.12188 16.807 2.19627 16.7141 2.29L13.8941 5.12L6.95406 12.05C6.86138 12.1434 6.78806 12.2543 6.73829 12.3761C6.68853 12.4979 6.6633 12.6284 6.66406 12.76ZM17.4241 4.41L20.2541 7.24L18.8341 8.66L16.0041 5.83L17.4241 4.41ZM8.66406 13.17L14.5941 7.24L17.4241 10.07L11.4941 16H8.66406V13.17Z"
            fill="#0430AD"
          />
        </svg>
      </span>
      <b-modal
        content-class="modal-scoped"
        body-class="modal-style"
        :hide-header="true"
        :hide-footer="true"
        size="xl"
      >
        <template>
         <h1>mckwm</h1>
                <button
                  class="save-btn"
                  type="submit"
                  value="Submit"
                >
                  บันทึก
                </button>
          </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  created() {
    console.log(this.params.value.sub_page_id);
  },
  methods: {
    goToPage(){
      this.$router.push("/editproduct/"+this.params.value.sub_page_id)
    }
  },
  computed: {
    ...mapGetters({
      activePage: "general/activePage",
      pointer: "general/pointer",
    }),
  },
  // props: {
  //   id: Number,
  // },
};
</script>

<style lang="scss" scoped>
</style>
