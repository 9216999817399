<template>
  <div class="pointer" @click="OrderDown()">
    <img src="@/assets/images/elements/iconDown.svg" alt="" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";


export default {
  computed: {
    ...mapGetters({
      pointer: "general/pointer"
    })
  },

  methods: {
    async OrderDown() {
      this.params.orderDownValue(this.params.value)
    }
  }
};
</script>

<style lang="scss" scoped></style>
