<template>
  <div>
    {{ params.value }}
  </div>
</template>

<script>
export default {
  mounted() {
    //console.log("params", this.params);
  },
  methods: {}
};
</script>
